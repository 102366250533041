import React from "react"
import Helmet from "react-helmet"
import headerLogo from "../../assets/images/HB_logo_big.jpg"

import favicon from "../../assets/images/icons/favicon.ico"

const HelmetWrapper = ({ pageTitle, pageDescription, pageKeywords, slug }) => (
  <Helmet>
    <title>{`${pageTitle}`}</title>
    <meta name="description" content={pageDescription} />
    <meta name="keywords" content={pageKeywords} />
    <meta name="viewport" content="width=device-width, user-scalable=no" />
    <link rel="shortcut icon" type="image/x-icon" href={favicon} />
    <link rel="canonical" href={`https://www.harmonybiosciences.com${slug}`} />
    <meta property="og:site_name" content="Harmony Biosciences" />
    <meta property="og:title" content={pageTitle} />
    <meta property="og:description" content={pageDescription} />
    <meta
      property="og:url"
      content={
        slug
          ? `https://www.harmonybiosciences.com${slug}/`
          : `https://www.harmonybiosciences.com/`
      }
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content={`https://www.harmonybiosciences.com${headerLogo}`}
    />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="500" />
    <meta property="og:image:height" content="200" />
    <meta name="twitter:title" content={pageTitle} />
    <meta
      name="twitter:url"
      content={
        slug
          ? `https://www.harmonybiosciences.com${slug}`
          : `https://www.harmonybiosciences.com/`
      }
    />
    <meta name="twitter:card" content="summary" />
    <meta
      name="google-site-verification"
      content="ZLHfHdJyORp3DKn_40tCezpqEhFXBfG0etNApsGsEsQ"
    />
    <script src="https://polyfill.io/v3/polyfill.min.js?version=3.52.1"></script>
  </Helmet>
)

export default HelmetWrapper
