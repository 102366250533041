import React from "react"
import { Link } from "gatsby"
import { isMobile } from "react-device-detect"
import myEmitter from "../../helper/emitter"

import "./index.scss"

const hamburgerClose = () => {
  isMobile && myEmitter.emit("HamburgerEvent", "close")
}
class AboutSubmenu extends React.Component {
  render() {
    return (
      <ul className="nav__submenu about_nav__submenu">
        <li className="nav__submenu-item ">
          <Link
            to="/about-us"
            onClick={hamburgerClose}
            activeClassName={"active"}
          >
            Overview
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/about-us/senior-leadership"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Senior Leadership
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/about-us/our-culture"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Culture
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/about-us/careers"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Careers
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/about-us/ethics-and-compliance"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Ethics/Compliance
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/about-us/contact-us"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Contact Us
          </Link>
        </li>
      </ul>
    )
  }
}

class GrantProgramsSubmenu extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">
        <li className="nav__submenu-item">
          <Link
            to="/funding-programs/patients-at-the-heart"
            onClick={hamburgerClose}
            activeClassName={"active"}
            partiallyActive={true}
          >
            Patients at the Heart
          </Link>
        </li>
        <li className="nav__submenu-item">
          <Link
            to="/funding-programs/progress-at-the-heart"
            onClick={hamburgerClose}
            activeClassName={"active"}
            partiallyActive={true}
          >
            Progress at the Heart
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/funding-programs/external-funding"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            External Funding
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/funding-programs/medical-education-grants"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Medical Education Grants
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/funding-programs/investigator-sponsored-studies"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Investigator Sponsored Studies
          </Link>
        </li>
      </ul>
    )
  }
}

class NewsSubmenu extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">
        <li className="nav__submenu-item ">
          <Link
            to="/newsroom"
            onClick={hamburgerClose}
            activeClassName={"active"}
            partiallyActive={true}
          >
            <nobr>Press Releases</nobr>
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/newsroom/investors"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Investors
          </Link>
        </li>
      </ul>
    )
  }
}

class PatientsSubmenu extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">
        <li className="nav__submenu-item ">
          <Link
            to="/patients"
            onClick={hamburgerClose}
            activeClassName={"active"}
            partiallyActive={true}
          >
            About&nbsp;Narcolepsy
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/patients/research-focus-areas"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Research&nbsp;Focus&nbsp;Areas
          </Link>
        </li>
        <li className="nav__submenu-item ">
          <Link
            to="/patients/educational-resources"
            onClick={hamburgerClose}
            activeClassName="active"
          >
            Educational&nbsp;Resources
          </Link>
        </li>
      </ul>
    )
  }
}

class NavBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeNavMenu: null,
    }
  }

  handleLeave = (index) => {
    !isMobile && this.setState({ activeNavMenu: null })
  }
  handleHover = (index) => {
    !isMobile && this.setState({ activeNavMenu: index })
  }

  handleClick = (index) => {
    !index && hamburgerClose()
    isMobile && this.setState({ activeNavMenu: index })
  }

  render() {
    return (
      <ul className={`harmony-nav ${this.props.className}`}>
        <li
          className="nav__menu-item parent-nav"
          onMouseLeave={() => this.handleLeave(1)}
        >
          <a
            onMouseEnter={() => this.handleHover(1)}
            onClick={() => this.handleClick(1)}
            activeClassName={"active"}
            partiallyActive={true}
          >
            About Us
          </a>
          {this.state.activeNavMenu === 1 && <AboutSubmenu />}
        </li>
        <li
          className="nav__menu-item parent-nav"
          onMouseLeave={() => this.handleLeave(2)}
        >
          <a
            onMouseEnter={() => this.handleHover(2)}
            onClick={() => this.handleClick(2)}
            activeClassName={"active"}
            partiallyActive={true}
          >
            Patients
          </a>
          {this.state.activeNavMenu === 2 && <PatientsSubmenu />}
        </li>
        <li>
          <Link
            to="/our-products"
            onClick={this.handleClick}
            activeClassName="active"
          >
            Our Products
          </Link>
        </li>
        <li>
          <Link
            to="/science"
            onClick={this.handleClick}
            activeClassName="active"
          >
            Science
          </Link>
        </li>
        <li
          className="nav__menu-item parent-nav"
          onMouseLeave={() => this.handleLeave(3)}
        >
          <a
            onMouseEnter={() => this.handleHover(3)}
            onClick={() => this.handleClick(3)}
            activeClassName={"active"}
            partiallyActive={true}
          >
            Community
          </a>
          {this.state.activeNavMenu === 3 && <GrantProgramsSubmenu />}
        </li>
        <li>
          <Link
            to="/newsroom"
            onClick={this.handleClick}
            activeClassName="active"
          >
            Newsroom
          </Link>
        </li>
        <li>
          <Link
            to="https://ir.harmonybiosciences.com/"
            onClick={this.handleClick}
            activeClassName="active"
          >
            Investors
          </Link>
        </li>
      </ul>
    )
  }
}

export default NavBlock
