import React from "react"
import { Row, Col } from "react-flexbox-grid"

import harmonyFooterLogo from "../../assets/images/harmony-logo-footer-horizontal.png"

const FooterBlock = props => {
  return (
    <footer id={props.id}>
      <Row>
        <Col xs={12} md={4}>
          <img src={harmonyFooterLogo} alt="Harmony Logo" className="" />
          <p className="">
            Harmony Biosciences
            <br />
            630 W. Germantown Pike, Suite 215 <br />
            Plymouth Meeting, PA 19462
            <br />
          </p>
          <p className="copyright-block">
            <a href="/" target="_blank">
              HarmonyBiosciences.com
            </a>
            <br />
            <span style={{ fontSize: 10 }}>
              Harmony Biosciences name and logo are registered trademarks.
            </span>
            <br />
            <span style={{ fontSize: 10 }}>
              &copy;2024 Harmony Biosciences. All Rights Reserved.
            </span>
            <br />
            <span style={{ fontSize: 10 }}>US-HAR-2400019/May 2024</span>
          </p>
        </Col>
        <Col xs={12} md={8}>
          {props.children}
        </Col>
      </Row>
    </footer>
  )
}

export default FooterBlock
