import React from "react"
import { Link, withPrefix, navigate } from "gatsby"
import { Row, Col } from "react-flexbox-grid"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Location } from "@reach/router"

import HeaderBlock from "../HeaderBlock"
import FooterBlock from "../FooterBlock"

import sdsPDF from "../../../static/static/Pitolisant_Hydrochloride_Tablets_RSD_United_States.pdf"
import bptw from "../../assets/images/Great_Place_To_Work.png"

import "../../scss/harmony.scss"
import "./index.scss"

class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  handleClickHere() {
    Cookies.set("cookieconsent", "true", { expires: 30000 })
    navigate("/privacy-policy-terms-of-use")
  }

  render() {
    return (
      <div id="harmony-web">
        <HeaderBlock />
        <div className="body-content">{this.props.children} </div>
        <FooterBlock id="ht-footer" jobCode={this.props.jobCode}>
          <Row>
            <Col xs={12} md={4}>
              <ul className="footer-links">
                <li className="page-section hidden-xs">About Us</li>
                <li className="hidden-xs">
                  <Link to="/about-us/senior-leadership">
                    Senior Leadership
                  </Link>
                </li>
                <li className="hidden-xs">
                  <Link to="/about-us/our-culture">Culture</Link>
                </li>
                <li className="hidden-xs">
                  <Link to="/about-us/careers">Careers</Link>
                </li>

                <li className="hidden-xs">
                  <Link to="/about-us/ethics-and-compliance">
                    Ethics/Compliance
                  </Link>
                </li>

                <li className="page-section hidden-xs">
                  <Link to="/funding-programs/patients-at-the-heart">
                    Community
                  </Link>
                </li>

                <li className="page-section">
                  <Link to="/about-us/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy-terms-of-use">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/privacy-policy-terms-of-use/#terms">
                    Terms of Use
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4}>
              <ul className="footer-links hidden-xs">
                <li className="page-section">Patients</li>
                <li>
                  <Link to="/patients">About Narcolepsy</Link>
                </li>
                <li>
                  <Link to="/patients/research-focus-areas">
                    Research Focus Areas
                  </Link>
                </li>
                <li>
                  <Link to="/patients/educational-resources">
                    EDUCATIONAL RESOURCES
                  </Link>
                </li>

                <li className="page-section">
                  <Link to="/science">Science</Link>
                </li>
                <li>
                  <a href={sdsPDF} target="_blank">
                    Safety Data Sheet
                  </a>
                </li>

                <li className="page-section">Newsroom</li>
                <li>
                  <Link to="/newsroom">Press Releases</Link>
                </li>

                <li className="page-section">
                  <Link to="https://ir.harmonybiosciences.com">Investors</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4}>
              <Row>
                <Col xs={6} md={12}>
                  <ul className="footer-links">
                    <li className="page-section">Social Media</li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/harmonybiosciences/"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/harmony_biosciences/"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/harmonybio" target="_blank">
                        X
                      </a>
                    </li>
                    <li>
                      <a
                        href={withPrefix(
                          "/static/Harmony_Corporate_Social_Media_Community_Guidelines__August_2023_Update.pdf"
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        COMMUNITY GUIDELINES
                      </a>
                    </li>
                    <li className="page-section">
                      <Link to="/sitemap">Sitemap</Link>
                    </li>
                  </ul>
                </Col>
                <Col xs={6} md={12}>
                  <img
                    src={bptw}
                    className="gptw"
                    width="60px"
                    alt="Great Place to Work Certification"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </FooterBlock>

        <CookieConsent
          location="bottom"
          buttonText="X"
          cookieName="cookieconsent"
          style={{ background: "#4a9c27" }}
          expires={30000}
          containerClasses={`cookie-popup-wrapper`}
          contentClasses={`content-wrapper`}
          buttonWrapperClasses={`btn-close-wrapper`}
        >
          This website uses cookies to improve the site and your experience. By
          continuing to browse this website, you are agreeing to accept our use
          of cookies. To learn more about our Privacy Policy,
          {` `}
          <button className="btn-click-here" onClick={this.handleClickHere}>
            click here
          </button>
          .
        </CookieConsent>
      </div>
    )
  }
}

export default Layout
