import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-flexbox-grid"

import NavBlock from "../NavBlock"
import myEmitter from "../../helper/emitter"

import harmonyLogo from "../../assets/images/harmony-logo-horizontal.png"
import harmonyLogoMobile from "../../assets/images/harmony-logo-mobile.png"

import "./index.scss"

let EventMobileMenu = null
class HeaderBlock extends React.Component {
  state = {
    hamburgerOpen: false,
  }

  constructor(props) {
    super(props)
  }

  handleHamburger = () => {
    if (this.state.hamburgerOpen) {
      this.setState({
        hamburgerOpen: false,
      })
    } else {
      this.setState({
        hamburgerOpen: true,
      })
    }
  }

  componentWillMount() {
    EventMobileMenu = myEmitter.addListener("HamburgerEvent", data => {
      data === "close" && this.setState({ hamburgerOpen: false })
    })
  }

  componentWillUnmount() {
    EventMobileMenu && EventMobileMenu.remove()
  }

  render() {
    return (
      <header id="harmony-header">
        <NavBlock
          className={`mobile-nav hide ${this.state.hamburgerOpen && "show"}`}
        />
        <Row>
          <Col xs={12} md={12} className="logo-container align-center-xs">
            <Link to="/">
              <img
                src={harmonyLogo}
                className="header-logo hidden-xs header-large"
                alt="Harmony logo"
              />
              <img
                src={harmonyLogoMobile}
                className="header-logo hide visible-xs"
                alt="Harmony logo"
              />
            </Link>
            <button
              aria-label="Close"
              className={`btn-hamburger visible-xs ${
                this.state.hamburgerOpen && "close"
              }`}
              onClick={this.handleHamburger}
            ></button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className="hidden-xs">
            <div className="nav-wrapper">
              <NavBlock className="desktop-nav" />
            </div>
          </Col>
        </Row>
      </header>
    )
  }
}

export default HeaderBlock
